export enum Stage {
    Production = "production",
    Staging = "staging",
    Development = "development",
}

export const IS_PROD = getStage() === Stage.Production

export function getStage(): Stage {
    if (process.env.REACT_APP_STAGE === "production") {
        return Stage.Production
    } else if (process.env.REACT_APP_STAGE === "staging") {
        return Stage.Staging
    } else {
        return Stage.Development
    }
}
