import { Box, Flex, Heading, Image, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import React from "react"

import { trackSponsoredMessageClicked } from "../segment"
import { Blob } from "./Blob"

export function Banner() {
    return (
        <Stack
            align={"center"}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}
            direction={{ base: "column", md: "row" }}
        >
            <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}>
                    <Text
                        as={"span"}
                        position={"relative"}
                        _after={{
                            content: "''",
                            width: "full",
                            height: "30%",
                            position: "absolute",
                            bottom: 1,
                            left: 0,
                            bg: "red.400",
                            zIndex: -1,
                        }}
                    >
                        Optimism
                    </Text>
                    <br />
                    <Text as={"span"} color={"blue.400"}>
                        ETH Faucet
                    </Text>
                </Heading>
                <Text color={"gray.500"}>
                    Get 0.002 oETH by bridging at least 1,500 USDC to a new wallet on Optimism! We support{" "}
                    <Link
                        href="https://gateway.optimism.io/"
                        color="red.400"
                        _hover={{ color: "red.500", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Optimism Gateway
                    </Link>
                    ,{" "}
                    <Link
                        href="https://o3swap.com/hub"
                        color="red.400"
                        _hover={{ color: "red.500", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        O3 Swap
                    </Link>
                    ,{" "}
                    <Link
                        href="https://cbridge.celer.network/#/transfer"
                        color="red.400"
                        _hover={{ color: "red.500", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        cBridge
                    </Link>
                    , and{" "}
                    <Link
                        href="https://app.hop.exchange/"
                        color="red.400"
                        _hover={{ color: "red.500", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Hop Exchange
                    </Link>
                    .
                </Text>
                <Text color={"gray.500"}>
                    This faucet is sponsored by{" "}
                    <Link
                        href="https://app.nekodex.org/?utm_source=faucet_website&utm_medium=sponsored_message&utm_campaign=optimism_faucet"
                        color="green.400"
                        _hover={{ color: "green.300", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackSponsoredMessageClicked("")}
                    >
                        Nekodex
                    </Link>
                    , leveraged trading on Optimism.
                </Text>
            </Stack>

            <Flex
                flex={1}
                justify={"center"}
                align={"center"}
                position={"relative"}
                w={"full"}
                display={["none", "initial"]}
            >
                <Blob
                    w={"100%"}
                    h={"118%"}
                    position={"absolute"}
                    top={"1%"}
                    left={"-15%"}
                    zIndex={-1}
                    color={useColorModeValue("blue.50", "blue.400")}
                />
                <Box
                    position={"relative"}
                    height={"300px"}
                    rounded={"2xl"}
                    boxShadow={"2xl"}
                    width={"full"}
                    overflow={"hidden"}
                >
                    <Image
                        alt={"Optimism Faucet"}
                        fit={"cover"}
                        align={"center"}
                        w={"100%"}
                        h={"100%"}
                        src={"/og-image.png"}
                    />
                </Box>
            </Flex>
        </Stack>
    )
}
