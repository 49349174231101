import { IS_PROD } from "../util/stage"

const APPSYNC_END_POINT_STAGING = "https://4ev2h5efzneilmxnuzxweztpn4.appsync-api.ap-northeast-1.amazonaws.com/graphql"
const APPSYNC_X_API_KEY_STAGING = "da2-owgd6uatvrggfgveggajgbwote"

const APPSYNC_END_POINT_PROD = "https://yal4oni26rfsdns36maeuewxz4.appsync-api.ap-southeast-1.amazonaws.com/graphql"
const APPSYNC_X_API_KEY_PROD = "da2-uj34hmfqbjbxtnnvunmpxtodxq"

const appSyncEndPoint = IS_PROD ? APPSYNC_END_POINT_PROD : APPSYNC_END_POINT_STAGING
const headers = {
    "x-api-key": IS_PROD ? APPSYNC_X_API_KEY_PROD : APPSYNC_X_API_KEY_STAGING,
    "Content-Type": "application/json",
}

enum Token {
    eth = "eth",
    usdc = "usdc",
}

enum State {
    waiting = "waiting", // in waiting list
    issued = "issued", // issued
}

interface IssuingResult {
    token: Token // issuing token name
    amount: number // issuing token amount
    state: State | null // issuing state
    txHash: string | null

    msg: string | null // extra info message
    // 1. The request of this address has completed. Tx hash: ${txHash}
    // 2. Your request is in queue. It may take a few minutes. Please come back to check later

    errorMsg: string | null // error message
    // 1. "Faucet is drained. Please come back later."
}

interface Result {
    issuingResults: IssuingResult[] | null
    errorMsg: string | null
    // error message
    // 1. Invalid address
    // 2. Invalid passcode
    // 3. Ineligible address
}

export async function getFaucet(address: string, passcode: string = ""): Promise<Result | null> {
    const result = await fetch(appSyncEndPoint, {
        method: "POST",
        headers,
        body: JSON.stringify({
            query: `
                mutation Faucet($address:String!, $passcode:String!) {
                    faucet(address: $address, passcode: $passcode) {
                        issuingResults {
                            token
                            amount
                            state
                            txHash
                            msg
                            errorMsg
                        }
                        errorMsg
                    }
                }
            `,
            variables: { address, passcode },
        }),
    })
        .then(res => res.json())
        .catch(error => console.error("error", error))

    return result.data.faucet
}
