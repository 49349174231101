import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Link,
    Stack,
    Text,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { ethers } from "ethers"
import React, { useCallback, useState } from "react"

import { HCAPTCHA_SITEKEY } from "../constant"
import {
    trackAddressInputFocused,
    trackRequestForOETH,
    trackRequestOETHResultShown,
    trackSponsoredMessageClicked,
} from "../segment"
import { getFaucet } from "../service/appSync"

export function FaucetDesign() {
    const toast = useToast()
    const hcaptchaTheme = useColorModeValue("light", "dark")

    const [address, setAddress] = useState("")
    const [hcaptchaPasscode, setHcaptchaPasscode] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const toastError = useCallback(
        description => {
            toast({
                title: "Error",
                description,
                status: "error",
                duration: 10_000,
                isClosable: true,
            })
        },
        [toast],
    )

    const handleSubmit = useCallback(async () => {
        trackRequestForOETH(address)
        try {
            setIsLoading(true)
            const isAddressValid = ethers.utils.isAddress(address)
            if (!isAddressValid) {
                trackRequestOETHResultShown(address, false, "Invalid address")
                return toastError("Invalid address")
            }
            const res = await getFaucet(address, hcaptchaPasscode)
            if (!res) {
                trackRequestOETHResultShown(address, false, "unknown error")
                return toastError("unknown error")
            }
            if (res.errorMsg) {
                trackRequestOETHResultShown(address, false, res.errorMsg)
                return toastError(res.errorMsg)
            }
            const sentTokens = res.issuingResults
            sentTokens?.forEach(({ token, amount, msg, errorMsg }) => {
                if (errorMsg) {
                    toastError(`${token}: ${errorMsg}`)
                    return
                }
                // NOTE: on success
                trackRequestOETHResultShown(address, true, msg || "")
                toast({
                    title: `${amount} ${token.toUpperCase()} is sent!`,
                    description: "You can now swap your USDC to ETH on Uniswap.",
                    status: "success",
                    duration: 10_000,
                    isClosable: true,
                })
            })
            // NOTE: reset form
            setAddress("")
            // setHcaptchaPasscode("")
        } catch (err) {
            // NOTE: for debugging
            console.warn("err: ", err)
            console.warn("err.toJSON() ", err.toJSON())
            console.warn("err.toJSON() message ", err.toJSON().message)
            console.warn("err.response.status: ", err.response?.status)

            // NOTE: for 400, 500 (status code returned from BE)
            if (err.response) {
                return toastError(err.response.data.error)
            }
            // NOTE: for 502, 504 (CloudFront errors), can't get http status code (err.response.status)
            toastError(err.message)
        } finally {
            setIsLoading(false)
        }
    }, [address, hcaptchaPasscode, toast, toastError])

    return (
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
            <Stack spacing={8}>
                <FormControl id="email">
                    <FormLabel>Ethereum address</FormLabel>
                    <Input
                        value={address}
                        onFocus={() => trackAddressInputFocused()}
                        onChange={e => setAddress(e.target.value)}
                        placeholder="Enter your wallet address"
                    />
                </FormControl>
                <Stack direction={{ base: "column", sm: "row" }} align={"center"} justify={"space-between"}>
                    {HCAPTCHA_SITEKEY ? (
                        <HCaptcha
                            onError={err => toastError(`hCaptcha: ${err}`)}
                            onExpire={() => setHcaptchaPasscode("")}
                            onVerify={token => setHcaptchaPasscode(token)}
                            theme={hcaptchaTheme}
                            sitekey={HCAPTCHA_SITEKEY}
                        />
                    ) : (
                        "HCAPTCHA_SITEKEY not found"
                    )}
                    <Box flexGrow={0.7} textAlign={"center"}>
                        <Button
                            w="100%"
                            bg={"blue.400"}
                            color={"white"}
                            _hover={{
                                bg: "blue.500",
                            }}
                            isDisabled={isLoading || !hcaptchaPasscode || !address}
                            isLoading={isLoading}
                            onClick={handleSubmit}
                        >
                            Request
                        </Button>
                        <Text mt="5px" display={["none", "none", "initial"]}>
                            Made possible thanks to{" "}
                            <Link
                                href="https://app.nekodex.org/?utm_source=faucet_website&utm_medium=sponsored_message&utm_campaign=optimism_faucet"
                                color="green.400"
                                _hover={{ color: "green.300", textDecoration: "underline" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => trackSponsoredMessageClicked(address)}
                            >
                                Nekodex
                            </Link>
                        </Text>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}
