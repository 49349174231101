import { Container } from "@chakra-ui/react"
import React from "react"

import { Banner } from "./component/Banner"
import { FaucetDesign } from "./component/FaucetDesign"

export function App() {
    return (
        <Container maxW={"7xl"}>
            <Banner />
            <FaucetDesign />
        </Container>
    )
}
