import { Stage } from "../util/stage"
import { WRITE_KEY } from "./config"

function getAnalytics() {
    return (window as any).analytics
}

export function start(stage: Stage) {
    const analytics = getAnalytics()
    if (!analytics) return

    analytics.load(WRITE_KEY)
    analytics.page()
}

export function track(name: string, options: Record<string, any>) {
    const analytics = getAnalytics()
    if (!analytics) return
    analytics.track(name, { ...options })
}
