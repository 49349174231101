import { ChakraProvider, ThemeConfig, extendTheme } from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"

import { App } from "./App"
import { start } from "./segment"
import { getStage } from "./util/stage"

function setupSegment() {
    start(getStage())
}
setupSegment()

const themeConfig: ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: false,
}
const theme = extendTheme({ config: themeConfig })

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById("root"),
)
