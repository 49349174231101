import { track } from "./base"

enum EventType {
    INPUT_FOCUSED = "input_focused",
    BUTTON_CLICK = "button_click",
    UI_CHANGED = "ui_changed",
}

interface UITrackPayload {
    eventType: EventType
    payload?: Record<string, string>
}

function uiTrack(funcName: string, payload: UITrackPayload) {
    track(funcName, payload)
}

export function trackAddressInputFocused() {
    uiTrack("ethereum_address_input_box", {
        eventType: EventType.INPUT_FOCUSED,
    })
}

export function trackRequestForOETH(address: string) {
    track("request_for_oeth", {
        eventType: EventType.BUTTON_CLICK,
        payload: {
            address,
        },
    })
}

export function trackRequestOETHResultShown(address: string, isSuccess: boolean, responseMsg: string) {
    track("request_oeth_result_shown", {
        eventType: EventType.UI_CHANGED,
        payload: {
            address,
            success: isSuccess,
            message: responseMsg,
        },
    })
}

// NOTE: sponsor: Perpetual Protocol
export function trackSponsoredMessageClicked(address: string) {
    track("sponsored_message_clicked", {
        eventType: EventType.BUTTON_CLICK,
        payload: {
            address,
        },
    })
}
